"use client";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid2,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import HeaderImage from "../img/background-istanbul1.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BlogDto from "../Dtos/BlogDto";
import { useParams } from "react-router-dom";
import theme from "../theme/theme";


export default function BlogDetails() {
  const { id } = useParams();
  // const {pg} = useSearchParams().get("pg");
  const [blog, setBlog] = useState<BlogDto>();
  const [blogs, setBlogs] = useState<BlogDto[]>([]);

  
  // API'den blog verilerini çeken asenkron fonksiyon
  const getBlogFunc = async () => {
    fetch("https://api.degeregitimiakademisi.com/getpost/" + id, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          // console.log(pg)
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: BlogDto) => {
        setBlog(data); // Blog verilerini state'e kaydediyoruz
      })
      .catch((error) => console.log("Error fetching blog content:", error));
  };

  const getBlogsFunc = async () => {
    fetch("https://api.degeregitimiakademisi.com/getpost?ps=5&p=0", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: any) => {
        setBlogs(data.posts); // Blog verilerini state'e kaydediyoruz
      })
      .catch((error) => console.log("Error fetching blog content:", error));
  };

  useEffect(()=>{
    getBlogFunc();
  });

  // useEffect, sadece `id` değiştiğinde çalışacak
  useEffect(() => {
    if (id) {
      
      getBlogsFunc(); // `id` mevcutsa blog verilerini çek
    }
  }, [id]); // `id` bağımlılık olarak tanımlandı

  return (
    <>
      <Parallax bgImage={HeaderImage} strength={100}>
        <div style={{ height: 500 }}></div>
      </Parallax>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid2 container spacing={7}>
          {/* Sol taraf: Son eklenen makaleler */}
          <Grid2
            size={{ lg: 3 }}
            sx={{
              display: { xs: "none", md: "none", lg: "block", xl: "block" },
            }}
          >
            <Typography
              sx={{ backgroundColor: "#976969", fontSize: 26, color: "white" }}
            >
              Son Eklenen Yazılar
            </Typography>
            <br />
            <Divider></Divider>
            <br />
            <Box
              sx={{
                ml: "2%",
                mb: 8,
                justifyContent: "center",
                display: "contents",
              }}
            >
              {blogs.map((article) => (
                <Box key={article.id}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      backgroundColor: theme.palette.secondary.light,
                      boxShadow: theme.shadows[1],
                      mb: 3,
                    }}
                  >
                    <a
                      href={"/makale/" + article.link + "/" + article.guid}
                    >
                      <CardMedia
                        sx={{ height: 200 }}
                        image={article.image}
                        //image="./img/dramsiz_disiplin.jpg"
                        title={article.image_alt}
                      />
                    </a>
                    <CardContent>
                      <a
                        href={
                          "/makale/" + article.link + "/" + article.guid
                        }
                      >
                        <Typography variant="body1">{article.title}</Typography>
                      </a>
                    </CardContent>
                  </Card>
                  {/* <Image src={""} alt="a"></Image> */}
                </Box>
              ))}
            </Box>
            <Divider
              sx={{ backgroundColor: "#976969", fontSize: 26 }}
            ></Divider>
          </Grid2>

          {/* Sağ taraf: Blog HTML içeriği */}
          <Grid2 size={{ lg: 8 }}>
            <Box>
              <Typography variant="h4" gutterBottom>
                {blog?.title}
              </Typography>
              {CheckBoxIcon.name}
              <div
                dangerouslySetInnerHTML={{ __html: blog?.content || "" }}
              ></div>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
}
