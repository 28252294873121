import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./Pages/Anasayfa";
import BlogDetails from "./Pages/BlogDetails";
import Blogs from "./Pages/Blogs";
import EbookDetails from "./Pages/EbookDetails";
import ErrorBoundary from "./Pages/ErrorBoundry";
import Layout from "./Pages/Layout/LayoutPage";
import Ebooks from "./Pages/Ebooks";

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/makaleler" element={<Blogs />} />
            <Route path="/e-kitaplar" element={<Ebooks />} />
            <Route path="/makale/:title/:id" element={<BlogDetails />} />
            <Route path="/ekitap/:title/:id" element={<EbookDetails />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
