// components/Footer.tsx
import { Box, Typography, Grid2, Link, IconButton } from "@mui/material";
import theme from "../theme/theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";


const Footer = () => {
  // const [posts, setPosts] = useState<[]>([]);
  // const accessToken = "01f539155628e780b4219ee2c783d177"; // Bu token'ı Instagram Graph API'den almanız gerekiyor
  // const userId = "1693687741412886";
  // const GetPosts = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://graph.instagram.com/${userId}/media?fields=id,caption,media_url,permalink&access_token=${accessToken}`
  //     );
  //     console.log(res);
  //     setPosts(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //const data = res.json();

  // useEffect(() => {
  //   GetPosts();
  // }, []);

  return (
    <footer>
      <Box
        component="footer"
        sx={{
          py: 4,
          px: 2,
          mt: "auto",
          background: theme.gradients.primaryGradient,
          bottom: 1,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Grid2 container spacing={5} justifyContent="space-around">
          {/* Hakkımızda Bölümü */}
          <Grid2
            component="div"
            sx={{ textAlign: "left" }}
            size={{ xs: 12, xl: 5, sm: 5, md: 4, lg: 4 }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.light,
                fontWeight: "bold",
                fontSize: 30,
              }}
            >
              Hedefimiz
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 14, color: "#797979" }}>
              Değer Eğitimi Akademisi, eğitim alanında değer temelli bir
              yaklaşımla çocuklar, gençler ve yetişkinler için özel olarak
              tasarlanmış programlar sunan bir platformdur. Temel amacı,
              bireylerin hem akademik hem de kişisel gelişimlerini desteklerken;
              etik, ahlaki ve toplumsal değerleri kazanmalarına yardımcı
              olmaktır. Bu platformda, eğitmenler ve uzmanlar rehberliğinde
              bireylerin yaşam boyu öğrenme yolculuklarında ihtiyaç duydukları
              değerleri kazanarak, kendilerine ve çevrelerine katkı sağlayacak
              şekilde gelişmelerine olanak tanınır. Değer Eğitimi Akademisi,
              disiplinler arası yaklaşımlar, projeler ve atölyeler aracılığıyla
              bireylerin, özgüvenli, sorumluluk sahibi ve topluma duyarlı
              bireyler olmalarına yardımcı olur. Eğitim programlarında; ailede
              değer eğitimi, sosyal beceriler geliştirme, toplum hizmetleri ve
              liderlik gibi konular işlenerek, bireylerin daha bütünsel bir
              gelişim süreci yaşamaları hedeflenir.
            </Typography>
          </Grid2>

          {/* Bağlantılar Bölümü */}
          <Grid2
            component="div"
            size={{ xs: 12, xl: 2, sm: 6, md: 6, lg: 2 }}
            sx={{ textAlign: "left" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.light,
                fontWeight: "bold",
                fontSize: 30,
              }}
            >
              Bağlantılar
            </Typography>
            <Link
              href="/"
              color="inherit"
              variant="body2"
              sx={{ display: "block", mb: 1 }}
            >
              Ana Sayfa
            </Link>
            <Link
              href="/e-kitaplar"
              color="inherit"
              variant="body2"
              sx={{ display: "block", mb: 1 }}
            >
              E-Kitaplar
            </Link>
            <Link
              href="/makaleler"
              color="inherit"
              variant="body2"
              sx={{ display: "block", mb: 1 }}
            >
              Makaleler
            </Link>
            
          </Grid2>

          {/* Sosyal Medya Bölümü */}
          <Grid2 component="div" size={{ xs: 6, xl: 4, sm: 6, md: 6, lg: 4 }}>
            {/* <InstagramFooter></InstagramFooter> */}
            {/* {posts.map((post,index) => (
              <div key={index}></div>))} */}

            <Typography variant="h6" gutterBottom>
              Bizi Takip Edin
            </Typography>
            <Box>
              <IconButton
                href="https://www.instagram.com/degeregitimiakademisi/"
                target="_blank"
                aria-label="Instagram"
                color="inherit"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/degerogretmeni"
                target="_blank"
                aria-label="Facebook"
                color="inherit"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/channel/UC4Kw66zXAjspwRKfO2hPyuA"
                target="_blank"
                aria-label="Youtube"
                color="inherit"
              >
                <YoutubeIcon />
              </IconButton>
              <IconButton>

              </IconButton>
            </Box>
          </Grid2>
        </Grid2>

        {/* Alt Metin */}
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mt: 4 }}
        >
          {"© "}
          {new Date().getFullYear()}
          {" Değer Eğitimi Akademisi. Tüm hakları saklıdır."}
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
