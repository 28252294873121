"use client";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import React, { useState } from "react";
import logo from "../img/logo.png";
import theme from "../theme/theme";
import styles from "./footer.module.css";

export default function Header() {
  const navItems = [
    "Anasayfa",
    //"Kuran Öğreniyorum",
    "E-Kitaplar",
    //"Seminerler",
    "Makaleler",
  ];
  const [mobileOpen, setMobileOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const StyledAppBar = styled(AppBar)(({ theme }) => ({
  //   position: "relative",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   flexShrink: 0,
  //   borderBottom: "1px solid",
  //   borderColor: theme.palette.divider,
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: "none",
  //   backgroundImage: "none",
  //   zIndex: theme.zIndex.drawer + 1,
  //   flex: "0 0 auto",
  // }));

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List sx={{ marginTop: 15 }}>
        {navItems.map((item) => (
          <div key={item}>
            <ListItem component="a" href={`/${item.toLowerCase()}`}>
              {/* <ListItemText primary={item} /> */}
              <Typography sx={{ color: "white", fontSize: 20 }}>
                {item}
              </Typography>
            </ListItem>
            <br />
            <hr />
            <br />
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div className={styles.content}>
        <AppBar
          component="nav"
          sx={{
            background: theme.gradients.primaryGradient,
            p: 3,
          }}
          position="sticky"
        >
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                {/* <MenuIcon /> */}
              </IconButton>
            )}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Avatar
                alt="Değerler Eğitimi"
                src={logo}
                sx={{ width: 200 }}
              ></Avatar>
            </Typography>

            {!isMobile && (
              <Box
                sx={{
                  display: { xs: "none", md: "block", alignItems: "center" },
                }}
              >
                {navItems.map((item, index) => (
                  <Button key={item} sx={{ color: "#fff", paddingLeft: 8 }}>
                    <Link
                      href={index == 0 ? `/` : `/${item.toLowerCase()}`}
                    >
                      {/* <a style={{ color: 'inherit', textDecoration: 'none' }}></a> */}
                      <Typography
                        sx={{
                          textShadow: theme.customShadows.primaryShadow,
                          fontSize: 15,
                          fontWeight: "bold",
                          color: theme.palette.secondary.main,
                        }}
                        letterSpacing={2}
                      >
                        {item}
                      </Typography>
                    </Link>
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 340 },
          }}
        >
          {drawer}
        </Drawer>
        {/* Toolbar spacing below */}
        <Toolbar />
      </div>
    </>
  );
}
