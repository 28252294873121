// theme/theme.ts
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    gradients: {
      primaryGradient: string;
      secondaryGradient: string;
    };
    customShadows: {
      primaryShadow: string;
      secondaryShadow: string;
    };
  }

  interface ThemeOptions {
    gradients?: {
      primaryGradient?: string;
      secondaryGradient?: string;
    };
    customShadows?: {
      primaryShadow?: string;
      secondaryShadow?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'Montserrat', sans-serif", // İstediğiniz fontları ekleyin
    h1: {
      fontFamily: "'Montserrat', sans-serif", // Başlıklar için farklı bir font
      fontWeight: 700,
      fontSize: "2.5rem",
      
    },
    body1: {
      fontFamily: "'Roboto', sans-serif", // Paragraf metinleri için farklı bir font
      fontSize: "1rem",
      fontWeight: 400,
    },
    button: {
      fontFamily: "'Roboto', sans-serif", // Butonlar için font ayarı
      textTransform: "none", // Buton metinlerinin küçük harf olmasını sağlamak
    },
  },
  palette: {
    primary: {
      main: "#CDB9D9",
      dark: "#004080",
      light:"#0a0a0a",

    },
    secondary: {
      main: "#00000094",
      dark: "#8a6296", 
      light:'#ffffff'
    },
    divider: "#250a0a",
    background: {
      paper: "#703c3c",
    },
    mode: "light",  
  },
  gradients: {
    primaryGradient: "linear-gradient(to right, #ffffff , #E9E4F0)",
    secondaryGradient: "linear-gradient(to right, #CDB9D9, #8a6296)",
  },
  customShadows: {
    primaryShadow: "-4px 15px 57px rgba(0,0,0,0);",
    secondaryShadow: "10px 4px 12px rgba(140, 80, 160, 0.3)",
  },
  shadows: [
    "none",
    "-4px 5px 17px rgba(0,0,0,0.4);",
    "0px 6px 16px rgb(0, 0, 0)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
    "0px 4px 12px rgba(0, 64, 128, 0.2)",
    "0px 6px 16px rgba(140, 80, 160, 0.2)",
  ],

  zIndex: {
    drawer: 1,
  },
});

export default theme;
