
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fab,
  Grid2,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import BlogDto from "../Dtos/BlogDto";
import theme from "../theme/theme";
import axios from "axios";

export default function Home() {
  const [blogs, setBlogs] = useState<BlogDto[]>([]);

  const GetBlogs = async () => {
    try {
      const res = await axios.get(`https://api.degeregitimiakademisi.com/getpost?ps=100&p=0`);
      console.log(res);
      setBlogs(res.data.posts);
    } catch (error) {
      console.log(error);
    }
  };

  function getSubstringWithoutTags(
    htmlString: string,
    startIndex: number,
    endIndex: number
  ): string {
    const container = document.createElement("div");

    container.innerHTML = htmlString;
    const textContent = container.textContent;

    return textContent!.substring(startIndex, endIndex) + '...';
  }

  useEffect(() => {
    GetBlogs();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "95vh",
          mb:10,
          mx: 'auto', width: '90%'
        }}
      >
        <Grid2 container spacing={6} justifyContent="left" alignItems="flex-start">
          {blogs.map((item, index) => (
            <Grid2
              key={index}
              size={{ lg:5, md: 4, sm: 6, xl:3, xs: 12 }}
              component={"div"}
              alignItems="flex-start"
            >
              <Card
                sx={{
                  maxWidth: '100%',
                  backgroundColor: theme.palette.secondary.light,
                  boxShadow:theme.shadows[1]
                }}
              >
                <a href={'/makale/' + item.link + '/' + item.guid}>
                  <CardMedia
                    sx={{ height:250  }}
                     image={`../../Upload/Posts/${item.image}`}
                    //image="./img/dramsiz_disiplin.jpg"
                    title={item.image_alt}
                  />
                </a>
                <CardContent sx={{height: {
          xs: 'auto',  // small screen (mobile)
          sm: 'auto',  // medium screen (tablet)
          md: 'auto',  // large screen (desktop)
          lg: 'auto',  // extra large screen
          xl:'auto',
        },}}>
                  <Typography gutterBottom variant="h5" component="div">
                    <a href={'/makale/' + item.link + '/' + item.guid}>{item.title}</a>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    component={"div"}
                    dangerouslySetInnerHTML={{
                      __html: getSubstringWithoutTags(item.content, 0, 200),
                    }}
                  ></Typography>
                </CardContent>
                <CardActions sx={{justifyContent:'flex-end'}}>
                 <Fab variant="extended" size="medium"  color="secondary" href={'/makale/' + item.link + '/' + item.guid}> 
                {/* <Fab variant="extended" size="medium"  color="secondary" href={'/BlogDetails/' + item.guid}> */}
        Devamı
      </Fab>
                  {/* <a href={'/blogs/' + item.link}>Devamı...</a> */}
                </CardActions>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
}
