import  { Component, ErrorInfo,ReactNode  } from 'react';
import { Navigate } from 'react-router-dom';

interface State {
  hasError: boolean;
}
interface Props {
  children: ReactNode;  // children'ı ReactNode olarak tanımlıyoruz
}
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true, };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/404" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
